import React from "react"
import Layout from "../components/Layout"
import ThreeCliffsBGImage from "../components/ThreeCliffsBGImage"
import { Link } from 'gatsby'

export default function newClients() {
  return (
    <Layout>
      <ThreeCliffsBGImage style={{}}>
        <div className="hero-image-canvas"></div>
      </ThreeCliffsBGImage>
      <div className="contents-wrapper">
        <div className="text-container">
          <h1>New patients</h1>

          <p>
            If you are a new client to Hartley Clinical Psychology - first of
            all a very warm welcome!
          </p>
          <p>
            Please <Link to="/contact">contact Sarah</Link> by phone or email to arrange your initial
            appointment. This is to make sure we are the right fit for each
            other and to give you information about the therapy process.
          </p>

          <p>
            After your first session, you will be able to book further sessions online.
          </p>
        </div>
      </div>
    </Layout>
  )
}
